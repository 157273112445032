import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`How a Slack bot is using analytics to better understand usage and improve the product offering.`}</h2>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Our team`}</a>{` had been exploring Slack and Facebook Messenger bots and recently launched a Slack bot, Game Monk — it enables teams to play trivia amongst themselves.`}</p>
    <p>{`On launch, the team wanted to better understand the usage of the bot to be able to improve the gameplay and user experience.`}</p>
    <h2>{`The issue is traditional analytics tools do not work well for bots`}</h2>
    <p><strong parentName="p">{`The tracking mechanisms are different.`}</strong></p>
    <ul>
      <li parentName="ul">{`Client side doesn’t work`}</li>
      <li parentName="ul">{`Event based tracking overlooks rich information from the chat threads`}</li>
    </ul>
    <p><strong parentName="p">{`The types of data captured are different`}</strong></p>
    <ul>
      <li parentName="ul">{`Users aren’t just clicking buttons or web links — they can post rich media into a bot like gifs and audio; pose free form, natural language questions; and even send in location information`}</li>
    </ul>
    <p><strong parentName="p">{`The processing is different`}</strong></p>
    <ul>
      <li parentName="ul">{`With so many different types of data, the processing is inherently different`}</li>
      <li parentName="ul">{`How do you handle sessions in Slack when you can have multiple users coming and going in a room, interacting with a bot?`}</li>
    </ul>
    <p><strong parentName="p">{`The types of metrics you can get with bots are much richer, and more actionable`}</strong></p>
    <ul>
      <li parentName="ul">{`There’s sentiment analysis, conversational analytics, and AI response effectiveness — all which can be used to improve the bot experience`}</li>
    </ul>
    <h2>{`Dashbot to the rescue!`}</h2>
    <p>{`Realizing analytics were missing, the team switched focus to build a bot specific analytics platform — enabling other bot makers to have rich analytics as well.`}</p>
    <p>{`With `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{`, we’re able to see core usage metrics and more importantly, bot specific analytics — sentiment analysis, conversational analytics, AI response effectiveness, and even the fully recreated session transcripts.`}</p>
    <h2>{`Team Metrics`}</h2>
    <p>{`One of the basic metrics we wanted to see is how many Slack Teams installed the bot and how frequently they engaged.`}</p>
    <p>{`With `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{`, we can do that:`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/06/07160052/0_hLvlcpLF9_68DKOK.png)
    </figure>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/06/07160523/0_e9Q2QMtaB-LPT8y6.png)
    </figure>In addition, we can drill down into the teams to see individual metrics
around usage, sentiment, and messaging.
    <h2>{`Session Handling`}</h2>
    <p>{`Session handling in Slack is quite interesting. Multiple users can interact with a bot in the same room and come and go — so what’s the session? This is quite a bit different from traditional web or mobile usage where one user is interacting one-on-one with the site or app.`}</p>
    <p>{`With `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{`, we’re able to calculate and report on true bot sessions. Notice how in some cases there are more users than sessions, and others more sessions than users, that’s because multiple users can be in the session at the same time.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/06/07160051/0_begx-Y4gS8CD_qkt.png)
    </figure>
At the same time, users can continue to interact in a chat room without
communicating with the bot — there needed to be a way to ignore those non-bot
messages, and we incorporated that into our platform.
    <h2>{`Sentiment Analysis`}</h2>
    <p>{`What do users think of your bot? Do they like it? Are they ecstatic or are they getting frustrated?`}</p>
    <p>{`The great thing about bots is — they’ll tell you!`}</p>
    <p>{`Not only can you see the sentiment based on the session and individual messages, but you can look into the message threads and see what people are actually saying.`}</p>
    <p>{`We’ve learned the trivia game can be quite challenging and people like to trash talk — both conditions can lead to negative sentiment, even though it’s not necessarily negative to the bot. Looking at the sentiment in the context of game play and the thread, one can see it’s often good natured ribbing amongst friends.`}</p>
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2016/06/07160050/0_axf7j_bvPmt6zzYD.png",
          "alt": null
        }}></img></figure>{`AI Response Effectiveness`}</h2>
    <p>{`How effective is your bot AI? How does it handle cases when it doesn’t understand the message? Does it prompt the user again, send a default response, or barrel along regardless of what was typed in?`}</p>
    <p>{`With `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` you can see the messages sent in and out, and can transverse the flows to see what inputs triggered a default response, and where the AI broke down. In addition, you can view the full session transcripts to see what occurred prior to the break down. Using this information, can help improve the bot experience and increase overall engagement.`}</p>
    <p>{`For example, in one session, when the question asked users to name as many NBA teams as they could, some of the users listed their responses inline separated by commas — the bot ignored those responses looking for single line answers instead. This is an opportunity to improve the bot handling as well as communicating to end users better.`}</p>
    <p>{`The game has known failure responses when answers aren’t in the right format — we can look at those outgoing responses and see what preceded them.`}</p>
    <p>{`For example, in the case of multiple choice questions, if the response isn’t recognized, the bot prompts: “Please answer A, B, C, or D.”`}</p>
    <p>{`One might think a common cause for this error would be someone writing the choice out instead of the letter, but it turns out it’s a combination of laughter and not knowing the answer. This can be an opportunity to improve the bot with skip or hint functions, or even a better response to the happiness — i.e. “glad you liked it!”`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/06/07160049/0_bDKsPhZFtlZKRYje.png)
    </figure>
At the same time, the bot prompts users for feedback after the game is over. We
can see the messages that were sent in after the feedback message, as well as
look at full threads to get a better idea of what was liked or disliked. Based
on responses, while the game can be fun, we can also see the game can be too
fast for some users.
    <h2>{`Session Threads`}</h2>
    <p>{`With `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{`, we’re able to see the top messages in and out, as well as the fully recreated session threads — with full multimedia — images, gifs, audio, locations, etc. This is useful for figuring out what leads to AI failures.`}</p>
    <p>{`In one example, an international team played the trivia game and wasn’t able to answer many of the US specific questions — e.g. “Popular US TV Shows” and “State Capitals”.`}</p>
    <p>{`Here’s a quick snippet of one of their threads. It quickly goes sideways with a series of obscenities in French.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/06/07160049/0_izMX4sA7gPujD_KK.png)
    </figure>
The funny thing is, despite not knowing many of the categories, they keep
playing:
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2016/06/07160048/0_OKgSP-3_bLOus8EY.png",
          "alt": null
        }}></img></figure>{`Get Started — Integration is Easy!`}</h2>
    <p>{`Bot specific analytics can help improve your bot experience and increase user engagement.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is simple to use — just add a few lines of code to your Slack or Facebook Messenger bot and you’re good to go. We handle the rest — all the data capture, processing, and reporting.`}</p>
    <p>{`You can see how easy it is at `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/sdk"
      }}>{`www.dashbot.io/sdk`}</a></p>
    <p>{`It’s free to use — you can sign up at `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`www.dashbot.io`}</a>{`. Simply integrate our sdk into your Slack or Facebook Messenger bot and you’ll see the data coming in immediately.`}</p>
    <hr></hr>
    <p><em parentName="p">{`Originally published at`}</em>{` `}<a parentName="p" {...{
        "href": "https://medium.com/@artemerritt/got-bot-analytics-1909c677a6fb"
      }}><em parentName="a">{`medium.com`}</em></a>{` `}<em parentName="p">{`on June 2, 2016.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      